import { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { evNavigationClick, evTopLevelNavigationClick } from 'events/headerFooter';
import { makeOpenLiveChat } from 'helpers/ClientUtils';
import { trackEvent, trackLegacyEvent } from 'helpers/analytics';
import { track } from 'apis/amethyst';
import { CUSTOMER_SERVICE_DROPDOWN } from 'constants/navigation';
import useMartyContext from 'hooks/useMartyContext';
import DynamicBanner from 'components/hf/zappos/DynamicBanner';
import Link from 'components/hf/HFLink';
import FocusTrap from 'components/common/FocusTrap';
import { withErrorBoundary } from 'components/common/MartyErrorBoundary';
import HtmlToReact from 'components/common/HtmlToReact';
import { stripSpecialCharsDashReplace as strip } from 'helpers';
import { selectIsVipOrEnrolling } from 'selectors/rewards';

import css from 'styles/components/hf/zappos/helpMenu.scss';

export const HelpMenu = props => {
  const {
    testId,
    marketplace: { phoneNumber, phoneNumberTel, phoneNumberVip, phoneNumberVipTel, chatWindowName }
  } = useMartyContext();

  const {
    isOpen,
    handleTopNavClick,
    Link,
    customerServiceNumber,
    customerServiceDropdown,
    onOpenLiveChat = makeOpenLiveChat(chatWindowName),
    isMobile,
    hideCSMenuMobile,
    isLiveChatEnabled
  } = props;

  const isVip = useSelector(selectIsVipOrEnrolling);

  const onHelpClick = useCallback(evt => {
    const {
      target: { tagName, innerText }
    } = evt;
    if (tagName === 'A') {
      trackEvent('TE_HEADER_CUSTOMER_SERVICE', 'Phone');
      trackLegacyEvent('Global', 'Header', '24-7-Help');
      track(() => [
        evTopLevelNavigationClick,
        {
          valueClicked: innerText || 'Header Phone'
        }
      ]);
    }
  }, []);

  const showCustomerServiceMenu = !(hideCSMenuMobile && isMobile);

  return (
    <div
      className={css.headerTopBar}
      data-test-id={testId('headerTopBar')}
      data-component-name={customerServiceDropdown.componentName}
      data-creative-id={customerServiceDropdown.creativeID}
    >
      <div className="headerContainer">
        {showCustomerServiceMenu && customerServiceDropdown?.componentName === 'subNavMenu' && (
          <div className={css.dropdownList}>
            <Link
              data-test-id={testId('headerCustomerServiceToggle')}
              to={customerServiceDropdown.heading.link}
              rel="noopener noreferrer"
              target="_blank"
              onClick={e => {
                handleTopNavClick(e);
                track(() => [
                  evTopLevelNavigationClick,
                  {
                    valueClicked: customerServiceDropdown.heading.text
                  }
                ]);
              }}
              data-shyguy={CUSTOMER_SERVICE_DROPDOWN}
              aria-expanded={isOpen(CUSTOMER_SERVICE_DROPDOWN)}
            >
              {customerServiceDropdown.heading.text}
            </Link>
            <FocusTrap active={isOpen(CUSTOMER_SERVICE_DROPDOWN)}>
              {focusRef => (
                <ul data-hfdropdown data-test-id={testId('headerCustomerServiceDropdown')} ref={focusRef}>
                  {customerServiceDropdown.subNavMenu.map(({ link, text, gae }) => {
                    const isChatLink = text?.toLowerCase().includes('chat');
                    const isSurveyLink = link?.toLowerCase().includes('survey');
                    if (isChatLink && isLiveChatEnabled) {
                      return (
                        <li key={text}>
                          <a
                            onClick={e => {
                              onOpenLiveChat(e);
                              trackEvent('TE_HEADER_CUSTOMER_SERVICE', strip(gae || text));
                              trackLegacyEvent('Main-Nav', 'CustomerService', strip(gae || text));
                              track(() => [
                                evNavigationClick,
                                {
                                  valueClicked: text,
                                  parentDropdown: 'Customer Service'
                                }
                              ]);
                            }}
                            data-test-id={testId('headerLiveChat')}
                            id="liveChat"
                            href={link}
                          >
                            {text}
                          </a>
                        </li>
                      );
                    } else if (!isChatLink) {
                      return (
                        <li key={text}>
                          <Link
                            to={link}
                            target={isSurveyLink ? '_blank' : '_self'}
                            rel="noopener noreferrer"
                            data-test-id={testId(strip(text))}
                            onClick={() => {
                              trackEvent('TE_HEADER_CUSTOMER_SERVICE', strip(gae || text));
                              trackLegacyEvent('Main-Nav', 'CustomerService', strip(gae || text));
                              track(() => [
                                evNavigationClick,
                                {
                                  valueClicked: text,
                                  parentDropdown: 'Customer Service'
                                }
                              ]);
                            }}
                          >
                            {text}
                          </Link>
                        </li>
                      );
                    }
                  })}
                </ul>
              )}
            </FocusTrap>
          </div>
        )}
        {customerServiceNumber?.componentName === 'pageContent' && (
          <HtmlToReact className={css.phoneNum} data-test-id={testId('headerPhone')} onClick={onHelpClick}>
            {customerServiceNumber.pageContent.body
              .replace(/{{number}}/g, isVip ? phoneNumberVip : phoneNumber)
              .replace(/href="numberTel"/g, `href="${isVip ? phoneNumberVipTel : phoneNumberTel}"`)}
          </HtmlToReact>
        )}
      </div>
    </div>
  );
};

HelpMenu.defaultProps = {
  DynamicBanner,
  Link
};

HelpMenu.propTypes = {
  isMobile: PropTypes.bool,
  hideCSMenuMobile: PropTypes.bool,
  isLiveChatEnabled: PropTypes.bool
};

export default withErrorBoundary('TopBar', HelpMenu);
