import type { AppState } from 'types/app';
import type { Killswitch } from 'types/killswitch';

export const selectKillswitches = (state: AppState) => state.killswitch;

export const selectKillswitch = <T = any>(state: AppState, killswitch: Killswitch) => {
  const ksValue = selectKillswitches(state)[killswitch];

  if (ksValue) {
    return ksValue as T;
  }

  return;
};
