import React, { Component, createRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import debounce from 'lodash.debounce';
import { weakMapMemoize } from 'reselect';

import { cn } from 'helpers/classnames';
import { evNavigationClick, evTopLevelNavigationClick } from 'events/headerFooter';
import { track } from 'apis/amethyst';
import RewardsTransparency from 'components/common/RewardsTransparency';
import SkipLinks from 'components/common/SkipLinks';
import Link from 'components/hf/HFLink';
import { saveExplicitSearchEventToLocalStorage } from 'helpers/searchInputUtils';
import { onEvent } from 'helpers/EventHelpers';
import { getSearchInputValue } from 'helpers/HFHelpers';
import { ensureClass, ensureNoClass } from 'helpers';
import { termEncoder } from 'helpers/SearchUtils';
import { trackEvent, trackLegacyEvent } from 'helpers/analytics';
import { getHFScreenSize } from 'helpers/HtmlHelpers';
import {
  checkForHFBreakpoint,
  fetchRewardsInfoForTopBanner,
  getCartCount,
  getZapposGlobalBannerData,
  handleHFSearchChange,
  handleHFSearchReset,
  handleSearchKeyUp,
  loadHFSearchUserTerms,
  searchByTerm,
  setFederatedLoginModalVisibility,
  setHFSearchSuggestionsActiveIndex,
  setHFSearchTerm,
  toggleMobileHeaderExpand
} from 'actions/headerfooter';
import {
  closeAllNavs,
  handleDocClickForNav,
  handleSubNavClick,
  handleSubNavClose,
  handleTopNavClick,
  handleTopNavCloseClick,
  setNavPositioning
} from 'actions/headerfooterNav';
import { changeCartCount, showCartModal } from 'actions/cart';
import { withErrorBoundary } from 'components/common/MartyErrorBoundary';
import FocusTrap from 'components/common/FocusTrap';
import HelpMenu from 'components/hf/zappos/HelpMenu';
import HeaderNav from 'components/hf/zappos/HeaderNav';
import BottomBanner from 'components/hf/zappos/BottomBanner';
import SearchSuggestions from 'components/hf/SearchSuggestions';
import marketplace from 'cfg/marketplace.json';
import konami from 'helpers/konami';
import batmanBounce from 'helpers/batmanBounce';
import { CUSTOMER_SERVICE_DROPDOWN } from 'constants/navigation';
import { selectHeaderSlotData } from 'selectors/headerFooter';
import { redirectTo, redirectToAuthenticationFor } from 'actions/redirect';
import { getIsLiveChatEnabled } from 'selectors/navigation';
import EmergencyBanner from 'components/EmergencyBanner';
import IconButton from 'tailwind/components/IconButton/IconButton';
import UtilityStrokeCloseSmallIcon from 'tailwind/components/Icons/UtilityStrokeCloseSmallIcon';
import UtilityStrokeSearchMediumIcon from 'tailwind/components/Icons/UtilityStrokeSearchMediumIcon';
import AccountDropdown from 'components/hf/zappos/AccountDropdown';
import FavoritesIcon from 'components/hf/zappos/FavoritesIcon';
import AccountIcon from 'components/hf/zappos/AccountIcon';
import CartIcon from 'components/hf/zappos/CartIcon';
import useMartyContext from 'hooks/useMartyContext';
import FederatedLoginModal from 'components/account/FederatedLoginModal';

import css from 'styles/containers/hf/zappos/header.scss';

const makeFocusTrapChildren = weakMapMemoize(
  (
    self,
    content,
    isMobileView,
    isCustomer,
    testId,
    isMobileHeaderExpanded,
    handleTopNavToggle,
    desktopStyles,
    subCatHeight,
    isOpen,
    isSubNavOpen,
    handleTopNavCloseClick,
    handleSubNavClick,
    handleSubNavClose,
    openedSubNav,
    navsThatHaveBeenOpened,
    hideCSMenuMobile,
    holmes,
    killswitch,
    customerServiceNumber,
    customerServiceDropdown,
    isLiveChatEnabled,
    handleHFSearchChange,
    handleHFSearchReset,
    handleSearchKeyUp,
    hasSuggestions,
    searchInputValue,
    isSearchOpen,
    isSearchFocused,
    suggestions,
    suggestionIndex,
    isZeroState,
    getCartCount
  ) => {
    const searchNode = (
      // hidden initially, shown in desktop
      <div className={cn(isMobileView ? css.searchContainerMobile : css.searchContainer, css.searchMin, { [css.showAnim]: isSearchOpen })}>
        {isMobileView && isSearchOpen && <div role="presentation" onClick={self.hideSearch} className={css.overlay} />}
        <form
          data-test-id={testId('headerSearchForm')}
          onSubmit={self.handleSearchSubmit}
          method="GET"
          action="/search"
          className={cn(css.searchbar, css.searchMin, { [css.searchbarMobile]: isMobileView })}
          data-search-container
          role="search"
          onReset={handleHFSearchReset}
        >
          {/*
            A lot of conflicting info around comboboxes in regards to a11y.
            Might be worth using a library to handle it down the line because
            I trust random libraries on npm more than I do myself.
          */}
          <input // eslint-disable-line jsx-a11y/no-access-key
            data-cs-mask={marketplace.dataMaskPiiElements}
            className={css.searchInput}
            type="search"
            name="term"
            id="searchAll"
            onFocus={self.handleSearchFocus}
            onChange={handleHFSearchChange}
            onKeyUp={handleSearchKeyUp}
            onClick={self.handleSearchClick}
            role="combobox"
            aria-controls={'hfSearchSuggest'}
            aria-owns={'hfSearchSuggest'}
            aria-autocomplete="list"
            aria-haspopup="listbox"
            aria-expanded={hasSuggestions || (isSearchFocused && isZeroState)}
            value={searchInputValue}
            placeholder={self.getPlaceholder()}
            accessKey="s"
            ref={self.searchInputRef}
            autoComplete="off"
          />

          <label htmlFor="searchAll">{self.getPlaceholder()}</label>

          {isMobileView && (
            <button type="reset" className={css.resetButton} aria-label="clear search" data-test-id={testId('headerSearchClearButton')}>
              clear
            </button>
          )}

          {!isMobileView && (
            <button className={css.searchSubmit} type="submit">
              Search
            </button>
          )}

          {!isMobileView && (
            <SearchSuggestions
              termSearched={searchInputValue}
              suggestions={suggestions}
              suggestionIndex={suggestionIndex}
              handleZeroStateDelete={self.handleZeroStateDelete}
              isZeroState={isZeroState}
              isOpen={isSearchFocused}
            />
          )}
        </form>

        {isMobileView && (
          <button
            aria-label="hide search"
            className={css.escapeButton}
            type="button"
            onClick={self.toggleShowSearch}
            data-test-id={testId('headerSearchCloseButton')}
          />
        )}

        {isMobileView && (
          <SearchSuggestions
            termSearched={searchInputValue}
            suggestions={suggestions}
            suggestionIndex={suggestionIndex}
            handleZeroStateDelete={self.handleZeroStateDelete}
            isZeroState={isZeroState}
            isOpen={isSearchFocused}
          />
        )}
      </div>
    );

    return focusRef => (
      <div className={css.zapposHeader} ref={focusRef}>
        <div className="headerContainer">
          <div className="flex w-full items-center justify-between px-6 py-1 md:py-4 lg:px-0">
            <div className="flex flex-grow">
              <div aria-hidden className={css.headerOverlay} onClick={self.handleMobileNavToggle}></div>
              <StoreSelectorWrapper
                content={content}
                closeAllNavs={closeAllNavs}
                storeSelector={content?.Header?.slotData?.['store-selector']}
                setHFSearchTerm={setHFSearchTerm}
                isMobileView={isMobileView}
              ></StoreSelectorWrapper>
              {!isMobileView && searchNode}
            </div>
            <div className={css.desktopIconBarWarpper}>
              {isCustomer && <FavoritesIcon />}
              {isCustomer ? <AccountDropdown container={focusRef} /> : <AccountIcon />}
              <CartIcon getCartCount={getCartCount} />
            </div>
            <span className={css.mobileIconBarWrapper}>
              <IconButton
                label="Show Search"
                onClick={self.toggleShowSearch}
                variant="outlined"
                data-test-id={testId('headerSearchIcon')}
                className="rounded-full border-none p-1 transition-colors duration-300 hover:bg-default-subtle hover:text-link-default"
              >
                <UtilityStrokeSearchMediumIcon size={32} />
              </IconButton>
              <CartIcon className="mr-2" headerCartTestId="headerCartMobile" getCartCount={getCartCount} />
              <button
                type="button"
                data-test-id={testId('headerHamberderMenu')}
                className={cn(css.hamberder, 'order-3')}
                onClick={self.handleMobileNavToggle}
                aria-label="Navigation"
                aria-pressed={isMobileHeaderExpanded}
              ></button>
            </span>
          </div>
          <nav
            className={cn('hfHeaderNav', {
              [css.mobileNavHidden]: !isMobileHeaderExpanded
            })}
            data-test-id={testId('headerNav')}
            data-headernav
          >
            <div className={css.mobileNavHeader}>
              <div className="isolate flex gap-x-2">
                {isCustomer && <FavoritesIcon headerFavoritesTestId="headerFavoritesMobile" />}
                <AccountIcon
                  headerAccountTestId="headerAccountMobile"
                  headerSignInTestId="headerSignInMobile"
                  data-shyguy="navAccountMenu"
                  onClick={e => {
                    if (isCustomer) {
                      e.preventDefault();
                      handleTopNavToggle(e);
                    }
                  }}
                />
              </div>
              <IconButton label="Close navigation" onClick={self.handleMobileNavToggle} size="small" variant="outlined">
                <UtilityStrokeCloseSmallIcon size={16} />
              </IconButton>
            </div>
            <ul>
              <HeaderNav
                desktopStyles={desktopStyles}
                subCatHeight={subCatHeight}
                isOpen={isOpen}
                isSubNavOpen={isSubNavOpen}
                handleTopNavClick={handleTopNavToggle}
                handleTopNavCloseClick={handleTopNavCloseClick}
                handleSubNavClick={handleSubNavClick}
                handleSubNavClose={handleSubNavClose}
                openedSubNav={openedSubNav}
                navsThatHaveBeenOpened={navsThatHaveBeenOpened}
                topLevelNavs={content?.Header?.slotData?.navmenu?.navMenu}
                subNavs={content?.HeaderMenues}
                isRecognized={isCustomer}
                firstNavLinkRef={self.firstNavLinkRef}
                hideCSMenuMobile={hideCSMenuMobile}
              />
            </ul>
          </nav>
          {isMobileView && searchNode}
          <div className={css.secondaryItems}>
            <HelpMenu
              handleTopNavClick={handleTopNavToggle}
              isOpen={isOpen}
              holmes={holmes}
              content={content}
              Link={Link}
              killswitch={killswitch}
              customerServiceNumber={customerServiceNumber}
              customerServiceDropdown={customerServiceDropdown}
              hideCSMenuMobile={hideCSMenuMobile}
              isLiveChatEnabled={isLiveChatEnabled}
            />
          </div>
        </div>
      </div>
    );
  }
);

export class Header extends Component {
  static displayName = 'Header';

  constructor(props) {
    super(props);
    this.onResize = debounce(this.onResize.bind(this), 500);
    this.searchInputRef = createRef();
    this.firstNavLinkRef = createRef();
    this.headerRef = createRef();
  }

  state = {
    isMobileView: null,
    isSearchOpen: false,
    isSearchFocused: false
  };

  componentDidMount() {
    const { isRemote, changeCartCount, konamiEasterEgg = konami, checkForHFBreakpoint, dispatchLoadHFSearchUserTerms } = this.props;
    const {
      features: { showAccountRewards }
    } = marketplace;

    const { handleDocClickForNav, fetchRewardsInfoForTopBanner } = this.props;

    // Set which viewport we're in the state
    checkForHFBreakpoint();
    // Handle clicks on the body to close any open dropdown
    onEvent(window, 'click', handleDocClickForNav, null, this);

    // Handle key events for navigating search suggestions
    onEvent(window, 'keydown', this.handleKeyDown, null, this);

    // Handle resizing for knowing if we're at a mobile breakpoint or not
    onEvent(window, 'resize', this.onResize, null, this);
    onEvent(window, 'load', this.onResize, null, this);
    this.onResize();

    // Add listener for cartcount changes when remote
    if (isRemote) {
      onEvent(
        document,
        'cart_item_count_change',
        ({ detail } = {}) => {
          if (typeof detail === 'number') {
            changeCartCount(detail);
          }
        },
        null,
        this
      );
    }

    if (showAccountRewards) {
      fetchRewardsInfoForTopBanner();
    }

    konamiEasterEgg(batmanBounce, 'batman');

    dispatchLoadHFSearchUserTerms('');
  }

  componentDidUpdate(prevProps) {
    const { pageType: prevPageType } = prevProps;
    const {
      getZapposGlobalBannerData,
      openedNav,
      isMobileHeaderExpanded,
      isMobile,
      toggleMobileHeaderExpand,
      pageType: currentPageType
    } = this.props;

    // Add body class for when dropdown is open
    if (!isMobile && openedNav && openedNav !== CUSTOMER_SERVICE_DROPDOWN) {
      ensureClass(document.body, 'inactive');
      return;
    }
    if (isMobileHeaderExpanded || this.isOpen('headerMyAccountDropdownToggle')) {
      ensureClass(document.body, 'inactive');
      this.focusFirstNavLink();
    } else {
      ensureNoClass(document.body, 'inactive');
    }

    // Close mobile menu when we go from mobile to desktop
    if (isMobileHeaderExpanded && !isMobile) {
      toggleMobileHeaderExpand();
    }

    // clicking on 'Items in Cart' top right button shouldn't render banner again
    if (prevPageType && currentPageType && prevPageType !== currentPageType && currentPageType !== 'cartModal') {
      getZapposGlobalBannerData();
    }

    if (this.state.isSearchOpen) {
      this.searchInputRef?.current?.focus();
    }
  }

  focusFirstNavLink = () => {
    this.firstNavLinkRef?.current?.focus();
  };

  headerTopNavClickCleanup = () => {
    // Recalculate nav positioning as the app ads muck things up a bit.
    // Need a timeout cuz closing the banner takes a few milliseconds
    setTimeout(this.onResize, 200);
  };

  handleTopNavToggle = e => {
    this.headerTopNavClickCleanup();
    this.props.handleTopNavClick(e);
  };

  handleKeyDown = e => {
    const { isMobileHeaderExpanded, toggleMobileHeaderExpand } = this.props;
    const isEscape = e.key === 'Escape';
    if (isEscape && isMobileHeaderExpanded) {
      toggleMobileHeaderExpand();
    }
    this.handleNavKeyDown(e);
  };

  handleMobileNavToggle = () => {
    const { toggleMobileHeaderExpand } = this.props;
    toggleMobileHeaderExpand();
    this.headerTopNavClickCleanup();
  };

  handleNavKeyDown = e => {
    const { keyCode } = e;
    const { openedNav, closeAllNavs } = this.props;
    if (openedNav && keyCode === 27) {
      closeAllNavs();
    }
  };

  onResize() {
    const { checkForHFBreakpoint, setNavPositioning } = this.props;
    checkForHFBreakpoint();
    setNavPositioning();
    this.setState({
      isMobileView: getHFScreenSize() === 'hf-mobile'
    });
  }

  isOpen = id => {
    const { openedNav } = this.props;
    return openedNav === id;
  };

  isSubNavOpen = id => {
    const { openedSubNav } = this.props;
    return openedSubNav === id;
  };

  handleSearchSubmit = e => {
    const {
      props: {
        redirectTo,
        searchByTerm,
        suggestionIndex,
        suggestions,
        setHFSearchTerm,
        isMobileHeaderExpanded,
        dispatchLoadHFSearchUserTerms,
        isZeroState,
        userSearchTerms,
        filter
      },
      context: { router },
      handleMobileNavToggle
    } = this;
    const { target } = e;
    e.preventDefault();
    const term = target['term'].value;
    const inputValueWithSuggestions = getSearchInputValue(term, suggestionIndex, suggestions);
    const facet = target.dataset.searchCategory;
    const { searchId } = target.dataset;

    dispatchLoadHFSearchUserTerms(term);

    if (isMobileHeaderExpanded) {
      handleMobileNavToggle();
    }

    if (isZeroState) {
      searchByTerm({ term }, router);
      trackEvent('TE_HEADER_SEARCHBAR_SUBMIT', term);

      const autosuggestionClicked = false;
      const autosuggestionShown = !!userSearchTerms?.length;

      saveExplicitSearchEventToLocalStorage(term, autosuggestionShown, autosuggestionClicked);
      return;
    }

    if (facet === 'kids') {
      // Nested in nav header search for kids
      const kidsTerm = `kids ${term}`;
      searchByTerm({ term: kidsTerm }, router);
      trackEvent('TE_HEADER_SEARCH_NAV', `${searchId}:${term}`);
      trackEvent('TE_HEADER_SEARCHBAR_SUBMIT', `${searchId}:${term}`);
      track(() => [
        evNavigationClick,
        {
          parent: searchId,
          searchOccurred: true,
          explicitSearchTerm: term
        }
      ]);
    } else if (facet) {
      // Nested in nav header search (Women/Men)
      searchByTerm({ term, facet }, router);
      trackEvent('TE_HEADER_SEARCH_NAV', `${searchId}:${term}`);
      trackEvent('TE_HEADER_SEARCHBAR_SUBMIT', `${searchId}:${term}`);
      track(() => [
        evNavigationClick,
        {
          parent: searchId,
          searchOccurred: true,
          explicitSearchTerm: term
        }
      ]);
    } else if (typeof suggestionIndex === 'number' && suggestions?.[suggestionIndex]) {
      // Search suggestion submit
      const item = suggestions[suggestionIndex];
      if (item?.searchUrl) {
        redirectTo(item.searchUrl);
      } else {
        const searchObj = { term: item.suggestion };
        if (item.categories?.length) {
          // For when we're using searchsuggest with a category
          searchObj.facet = `zc2/${termEncoder(item.categories[0])}`;
        }
        searchByTerm(searchObj, router);
        trackEvent('TE_HEADER_SEARCH_SUGGESTION', inputValueWithSuggestions);
        trackEvent('TE_HEADER_SEARCHBAR_SUBMIT', inputValueWithSuggestions);
        setHFSearchTerm(item.suggestion);

        const autosuggestionClicked = true;
        const autosuggestionShown = true;
        saveExplicitSearchEventToLocalStorage(term, autosuggestionShown, autosuggestionClicked);
      }
    } else {
      // Normal header search
      searchByTerm({ term, filter }, router);
      trackEvent('TE_HEADER_SEARCHBAR_SUBMIT', term);
      const autosuggestionClicked = !!suggestions?.length;
      const autosuggestionShown = false;
      saveExplicitSearchEventToLocalStorage(term, autosuggestionShown, autosuggestionClicked);
    }
  };

  handleCloseFederatedLogin = () => {
    const {
      federatedLoginModal: { redirectOnClose },
      setFederatedLoginModalVisibility,
      redirectTo
    } = this.props;
    setFederatedLoginModalVisibility(false);
    !!redirectOnClose && redirectTo(redirectOnClose);
  };

  makeSkipLink = () => {
    const links = [{ id: 'main', value: 'Skip to main content' }];
    return <SkipLinks links={links} />;
  };

  getPlaceholder = () => {
    const {
      holmes: { firstName },
      isMobile
    } = this.props;
    if (isMobile) {
      return 'Shoes, clothing, etc.';
    }
    if (firstName) {
      return `${decodeURIComponent(firstName)}, search for shoes, clothes, etc`;
    }
    return 'Search for shoes, clothes, etc.';
  };

  handleSearchFocus = e => {
    const { isMobileHeaderExpanded, handleHFSearchChange } = this.props;
    const { handleMobileNavToggle } = this;

    this.setState({ isSearchFocused: true });

    if (isMobileHeaderExpanded) {
      handleMobileNavToggle();
    } else {
      handleHFSearchChange(e);
    }
  };

  handleSearchClick = () => {
    this.searchInputRef?.current?.focus(); // When the search input is clicked from the mobile expanded header, ensure it gets focused
  };

  toggleShowSearch = () => {
    this.setState({ isSearchOpen: !this.state.isSearchOpen });
  };

  hideSearch = () => {
    setTimeout(() => {
      // need to allow a search term to navigate before blurring
      this.setState({ isSearchOpen: false });
      this.setState({ isSearchFocused: false });
    }, 100);
  };

  // update the redux state with the new localstorage values
  handleZeroStateDelete = () => this.props.dispatchLoadHFSearchUserTerms('');

  render() {
    const { testId } = this.context;
    const { isMobileView, isSearchOpen, isSearchFocused } = this.state;
    const {
      content,
      customerServiceNumber,
      customerServiceDropdown,
      desktopStyles,
      federatedLoginModal,
      globalBanner,
      holmes,
      handleHFSearchChange,
      handleHFSearchReset,
      handleSearchKeyUp,
      handleSubNavClick,
      handleSubNavClose,
      handleTopNavCloseClick,
      isCustomer,
      isMobileHeaderExpanded,
      isRemote,
      killswitch,
      subCatHeight,
      navsThatHaveBeenOpened,
      openedSubNav,
      savedSuggestions,
      suggestions,
      userSearchTerms,
      isZeroState,
      suggestionIndex,
      term,
      emergencyBannerData,
      hideCSMenuMobile = true,
      isLiveChatEnabled,
      getCartCount
    } = this.props;
    const { isOpen, isSubNavOpen, handleTopNavToggle } = this;
    const hasSuggestions = !!(suggestions && suggestions.length);
    const { isFederatedLoginModalShowing, returnTo } = federatedLoginModal;
    const { Global: { slotData: { federatedLoginModalCopy } = {} } = {} } = content;

    const searchInputValue = getSearchInputValue(term, suggestionIndex, isZeroState ? userSearchTerms : suggestions, savedSuggestions);

    return (
      <header
        role="banner"
        className={css.zapposHeaderContainer}
        data-header-container
        data-test-id={testId('headerContainer')}
        ref={this.headerRef}
      >
        {/*
          Show hidden fallback msg so we can know we're using the fallback content data and something
          is definitely probably very wrong.
         */}
        {content?.fallback && <p className={css.hidden}>***Using Fallback Header***</p>}
        {this.makeSkipLink()}
        <div className="relative z-10">
          {emergencyBannerData && <EmergencyBanner />}
          {!emergencyBannerData?.message && <BottomBanner globalBanner={globalBanner} />}
        </div>
        <div aria-hidden className={cn(css.headerOverlay, css.headerOverlayDesktop)} onClick={this.handleMobileNavToggle} />

        <FocusTrap active={isMobileHeaderExpanded || isOpen('headerMyAccountDropdownToggle')}>
          {makeFocusTrapChildren(
            this,
            content,
            isMobileView,
            isCustomer,
            testId,
            isMobileHeaderExpanded,
            handleTopNavToggle,
            desktopStyles,
            subCatHeight,
            isOpen,
            isSubNavOpen,
            handleTopNavCloseClick,
            handleSubNavClick,
            handleSubNavClose,
            openedSubNav,
            navsThatHaveBeenOpened,
            hideCSMenuMobile,
            holmes,
            killswitch,
            customerServiceNumber,
            customerServiceDropdown,
            isLiveChatEnabled,
            handleHFSearchChange,
            handleHFSearchReset,
            handleSearchKeyUp,
            hasSuggestions,
            searchInputValue,
            isSearchOpen,
            isSearchFocused,
            suggestions,
            suggestionIndex,
            isZeroState,
            getCartCount
          )}
        </FocusTrap>
        <RewardsTransparency />
        {isFederatedLoginModalShowing && isRemote && (
          <FederatedLoginModal
            copy={federatedLoginModalCopy}
            handleClose={this.handleCloseFederatedLogin}
            isOpen={isFederatedLoginModalShowing}
            returnTo={returnTo}
          />
        )}
      </header>
    );
  }
}

export const StoreSelectorWrapper = ({ content, setHFSearchTerm }) => {
  const { testId } = useMartyContext();
  const logo = content?.Header?.slotData?.logo?.images?.[0];

  if (!logo?.src) {
    return null;
  }

  return (
    <div
      className={css.storeSelector}
      data-component-name={content?.Header?.slotData?.['store-selector']?.componentName}
      data-creative-id={content?.Header?.slotData?.['store-selector']?.creativeID}
    >
      <Link
        data-test-id={testId('headerLogo')}
        onClick={() => {
          setHFSearchTerm('');
          trackLegacyEvent('Global-Header-zapposheader', 'Promos', 'header-logo');
          trackEvent('TE_HEADER_LOGO', logo.gae || logo.alt);
          track(() => [
            evTopLevelNavigationClick,
            {
              valueClicked: 'Header Logo'
            }
          ]);
        }}
      >
        <img alt={logo.alt} fetchpriority="high" height={logo.height} width={logo.width} src={logo.src} />
      </Link>
    </div>
  );
};

Header.contextTypes = {
  router: PropTypes.object, // Not passed when we're a remote HF
  testId: PropTypes.func
};

function mapStateToProps(state) {
  const {
    cookies,
    headerFooter: {
      filter,
      content,
      desktopStyles,
      federatedLoginModal,
      globalBanner,
      isMobileHeaderExpanded,
      isMobile,
      isRemote,
      mobileStyles,
      openedNav,
      openedSubNav,
      navsThatHaveBeenOpened,
      savedSuggestions,
      suggestions,
      userSearchTerms,
      isZeroState,
      suggestionIndex,
      term
    },
    killswitch,
    holmes,
    environmentConfig: { isMockApi },
    router: { location },
    pageView: { pageType },
    emergencyBanner: { data: emergencyBannerData }
  } = state;
  return {
    filter,
    content,
    customerServiceNumber: selectHeaderSlotData('clthours', state),
    customerServiceDropdown: selectHeaderSlotData('customer-service-menu', state),
    desktopStyles,
    federatedLoginModal,
    globalBanner,
    holmes,
    isMobileHeaderExpanded,
    isMockApi,
    isCustomer: !!cookies['x-main'],
    isMobile,
    isRemote,
    killswitch,
    location,
    subCatHeight: mobileStyles.subCatHeight,
    navsThatHaveBeenOpened,
    openedNav,
    openedSubNav,
    savedSuggestions,
    suggestionIndex,
    suggestions,
    userSearchTerms,
    isZeroState,
    term,
    pageType,
    emergencyBannerData,
    isLiveChatEnabled: getIsLiveChatEnabled(state)
  };
}

const HeaderConnected = connect(mapStateToProps, {
  changeCartCount,
  checkForHFBreakpoint,
  closeAllNavs,
  fetchRewardsInfoForTopBanner,
  getCartCount,
  getZapposGlobalBannerData,
  handleDocClickForNav,
  handleHFSearchChange,
  handleHFSearchReset,
  handleSearchKeyUp,
  handleSubNavClick,
  handleSubNavClose,
  handleTopNavClick,
  handleTopNavCloseClick,
  redirectTo,
  redirectToAuthenticationFor,
  searchByTerm,
  setHFSearchTerm,
  setHFSearchSuggestionsActiveIndex,
  dispatchLoadHFSearchUserTerms: loadHFSearchUserTerms,
  setFederatedLoginModalVisibility,
  setNavPositioning,
  showCartModal,
  toggleMobileHeaderExpand
})(Header);

const HeaderConnectedWithErrorBoundary = withErrorBoundary('Header', HeaderConnected);

export default HeaderConnectedWithErrorBoundary;
