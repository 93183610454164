import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import Link from 'components/hf/HFLink';
import { evNavigationClick } from 'events/headerFooter';
import { track } from 'apis/amethyst';
import { stripSpecialCharsDashReplace } from 'helpers';
import { trackEvent, trackLegacyEvent } from 'helpers/analytics';
import { withErrorBoundary } from 'components/common/MartyErrorBoundary';
import { setHFSearchTerm, updateOriginalTerm } from 'actions/headerfooter';
import useMartyContext from 'hooks/useMartyContext';

import css from 'styles/components/hf/zappos/footerNav.scss';

export const FooterNav = ({ data }) => {
  const heading = data?.heading?.text;
  const { footerMenu } = data || {};
  const dispatch = useDispatch();
  const { testId } = useMartyContext();

  if (!footerMenu) {
    return null;
  }

  return (
    <div data-component-name={data?.componentName} data-creative-id={data?.creativeID}>
      {heading && <h3 className={css.heading}>{heading}</h3>}
      <ul className={css.list}>
        {footerMenu &&
          footerMenu.map(({ text, link, gae }) => (
            <li key={text}>
              <Link
                data-test-id={testId(text)}
                to={link}
                onClick={() => {
                  dispatch(setHFSearchTerm(''));
                  dispatch(updateOriginalTerm());
                  trackEvent('TE_FOOTER_NAV', `${heading}:${gae || text}`);
                  trackLegacyEvent('Footer', stripSpecialCharsDashReplace(heading), stripSpecialCharsDashReplace(gae || text));
                  track(() => [
                    evNavigationClick,
                    {
                      valueClicked: text,
                      parentDropdown: heading,
                      isFooter: true
                    }
                  ]);
                }}
              >
                {text}
              </Link>
            </li>
          ))}
      </ul>
    </div>
  );
};

FooterNav.contextTypes = {
  testId: PropTypes.func
};

export default withErrorBoundary('FooterNav', FooterNav);
