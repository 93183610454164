import React, { useEffect, useRef } from 'react';
import { connect } from 'react-redux';

import { cn } from 'helpers/classnames';
import Timer from 'components/landing/Timer';
import { NON_ALPHA_NUMERIC_RE_GEN, VALID_DATE } from 'common/regex';
import { trackEvent as defaultTrackEvent, getAmethystPageType } from 'helpers/analytics';
import useEvent from 'hooks/useEvent';
import useMartyContext from 'hooks/useMartyContext';
import { evBannerClick, evBannerImpression } from 'events/headerFooter';
import { track } from 'apis/amethyst';
import HtmlToReact from 'components/common/HtmlToReact';

import css from 'styles/components/hf/zappos/bannerItem.scss';

const justifyContentStyleMap = {
  left: 'flex-start',
  center: 'center',
  right: 'flex-end'
};

export const BannerItem = ({ slotData, slotName, pageType, isRemote, trackEvent = defaultTrackEvent }) => {
  const {
    heading,
    content,
    href,
    linktext,
    gae,
    backgroundColor,
    sameLine = true,
    linkColor,
    linkColorHover,
    linkColorVisited,
    // For future proofing - we want the ability to add an 'iconUrl' as a parameter for when we add icons to the banner
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    iconUrl,
    textAlign,
    contentWidth,
    color,
    componentName,
    creativeID
  } = slotData;
  let hovered = false;
  let timer = null;
  const ref = useRef();

  useEffect(() => {
    if (slotData) {
      track(() => [
        evBannerImpression,
        {
          slotData,
          text: ref.current?.innerText,
          slotName,
          pageType: getAmethystPageType(pageType)
        }
      ]);
    }
  }, [slotData, slotName, pageType]);

  const onClick = e => {
    const { target } = e;
    if (target.tagName === 'A') {
      const { textContent, href } = target;

      track(() => [
        evBannerClick,
        {
          bannerAction: 3,
          linkUrl: href,
          slotName,
          bannerText: content,
          pageType: getAmethystPageType(pageType)
        }
      ]);

      const uniqueGaeValue = `${gae}-${textContent.replace(NON_ALPHA_NUMERIC_RE_GEN(), '')}`;
      trackEvent('TE_GLOBAL_BANNER_LINK_CLICK', uniqueGaeValue);
    }
  };

  // If User stays for two second then only capture amethyst event logic
  const mouseOver = () => {
    if (!hovered) {
      timer = setTimeout(() => {
        track(() => [
          evBannerClick,
          {
            bannerAction: 2,
            slotName,
            bannerText: content,
            pageType: getAmethystPageType(pageType)
          }
        ]);
      }, 2000);
      hovered = true;
    }
  };

  const mouseLeave = () => {
    hovered = false;
    clearTimeout(timer);
  };

  useEvent(ref?.current, 'click', onClick);

  const linkStyles = {
    '--bannerItemLink': linkColor,
    '--bannerItemLinkHover': linkColorHover,
    '--bannerItemLinkVisited': linkColorVisited
  };
  const styleContainer = { backgroundColor, ...linkStyles };
  const style = {
    textAlign,
    justifyContent: justifyContentStyleMap[textAlign],
    width: contentWidth,
    color
  };

  // Allowing all HTML tags except what's explicitly below in the RegEx.
  const stripRegEx = /<(\/?|!?)(p|div|article|section)>/g;

  const filteredContent = content?.replace(stripRegEx, '') || '';
  const linkContent = linktext && href ? `<a href=${href}>${linktext}</a>` : '';
  const updatedContent = filteredContent ? `${filteredContent}&nbsp;${linkContent}` : linkContent;

  const formattedHeading = heading ? <div className={cn(css.heading, { [css.multipleLines]: sameLine === 'false' })}>{heading}</div> : '';

  const contents = updatedContent.split(/<countdown>(.*?)<\/countdown>/g);

  const formattedContent = contents.map(content => {
    if (content.match(VALID_DATE)) {
      return <Timer key={content} date={content} className={css.content} />;
    }

    return (
      <HtmlToReact isRemote={isRemote} className={css.content} key={content}>
        {content}
      </HtmlToReact>
    );
  });

  const allContent = formattedHeading ? (
    <>
      {formattedHeading}
      {sameLine !== 'false' && <>&nbsp;</>}
      {formattedContent}
    </>
  ) : (
    formattedContent
  );

  const { testId } = useMartyContext();

  return (
    // eslint-disable-next-line jsx-a11y/mouse-events-have-key-events
    <div
      onMouseOver={mouseOver}
      onMouseLeave={mouseLeave}
      style={styleContainer}
      className={css.container}
      data-component-name={componentName}
      data-creative-id={creativeID}
    >
      <div
        style={style}
        ref={ref}
        className={cn(css.innerContainer, {
          [css.linkColor]: linkColor && linkColorHover && linkColorVisited
        })}
        data-gae={gae}
        data-test-id={testId('innerContainer')}
      >
        {allContent}
      </div>
    </div>
  );
};

export const mapStateToProps = state => ({
  pageType: state.pageView.pageType,
  isRemote: state.headerFooter.isRemote
});

export default connect(mapStateToProps)(BannerItem);
